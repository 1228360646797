/**
 * ADDI - UI-Foundation
 * Do not edit directly
 * Generated on Wed, 28 Aug 2024 14:40:41 GMT
 */

module.exports = {
  "AssetAddiIconPngSmallUrl": "https://statics.addi.com/assets/addi-new-icon.png",
  "AssetAddiIconPngMediumUrl": "https://statics.addi.com/assets/addi-new-icon.png",
  "AssetAddiIconPngLargeUrl": "https://statics.addi.com/assets/addi-new-icon.png",
  "AssetAddiIconSvgUrl": "https://statics.addi.com/assets/addi-new-icon.svg",
  "AssetAddiIconLinealSvgUrl": "https://statics.addi.com/assets/addi-icon-lineal.svg",
  "AssetAddiIconLinealBlackSvgUrl": "https://statics.addi.com/assets/addi-logo-black.svg",
  "AssetAddiLogoPngSmallUrl": "https://statics.addi.com/assets/addi-new-logo.png",
  "AssetAddiLogoPngMediumUrl": "https://statics.addi.com/assets/addi-new-logo.png",
  "AssetAddiLogoPngLargeUrl": "https://statics.addi.com/assets/addi-new-logo.png",
  "AssetAddiLogoSvgUrl": "https://statics.addi.com/logo/v2/main/addi-logo.svg",
  "AssetAddiLogoFilledSvgUrl": "https://statics.addi.com/assets/addi-logo-filled.svg",
  "AssetAddiFlashLogoSvgUrl": "https://statics.addi.com/addi-flash/ADDI_FLASH.svg"
};